import { zenoPlayClient } from '~/server/utils/fetchClient'

export type GDPRResponse = {
  country?: string
  gdprCountry: boolean
}

export const getGDPR = async (): Promise<GDPRResponse> => {
  try {
    return await zenoPlayClient(`gdpr`)
  } catch (error) {
    console.error('Error fetching GDPR response', error)
    return {
      gdprCountry: false,
    }
  }
}
