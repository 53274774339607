import { defineStore } from 'pinia'
import type { GDPRResponse } from '~/api/gdpr.api'
import { getGDPR } from '~/api/gdpr.api'

export const useCookieConsentStore = defineStore('cookie-consent-store', () => {
  const gdpr = ref<GDPRResponse | null>(null)
  const categories = ref<string[]>([])
  const consented = ref(false)

  const isConsentRequired = computed(() => {
    return gdpr.value && gdpr.value.gdprCountry
  })

  const isAnalyticsAccepted = computed(() => {
    return consented.value && categories.value.includes('analytics')
  })

  const isTargetingAccepted = computed(() => {
    return consented.value && categories.value.includes('targeting')
  })

  const fetchGDPR = async () => {
    gdpr.value = await getGDPR()

    if (!isConsentRequired.value) {
      categories.value = ['necessary', 'analytics', 'targeting']
      consented.value = true
    }
  }

  return {
    gdpr,
    consented,
    categories,
    isConsentRequired,
    isAnalyticsAccepted,
    isTargetingAccepted,
    fetchGDPR,
  }
})
